import { default as backup_45introdvK6vJrECQMeta } from "/builds/stavax/stavax-frontend/pages/account/backup-intro.vue?macro=true";
import { default as backuporHvffj8pMMeta } from "/builds/stavax/stavax-frontend/pages/account/backup.vue?macro=true";
import { default as manage_45connectcpW5IDBRL9Meta } from "/builds/stavax/stavax-frontend/pages/account/manage-connect.vue?macro=true";
import { default as upgrade_45walletNiJm0C3CReMeta } from "/builds/stavax/stavax-frontend/pages/account/upgrade-wallet.vue?macro=true";
import { default as app_45securitypOiUpkSHx5Meta } from "/builds/stavax/stavax-frontend/pages/app-security.vue?macro=true";
import { default as connection_45successzvR23ngDedMeta } from "/builds/stavax/stavax-frontend/pages/connection-success.vue?macro=true";
import { default as depositvgkZrSh8wQMeta } from "/builds/stavax/stavax-frontend/pages/currency/deposit.vue?macro=true";
import { default as indexAnYFKmfX4UMeta } from "/builds/stavax/stavax-frontend/pages/currency/index.vue?macro=true";
import { default as qr_45codeKZbNicGwLdMeta } from "/builds/stavax/stavax-frontend/pages/currency/qr-code.vue?macro=true";
import { default as dashboard0TcwC5SXfxMeta } from "/builds/stavax/stavax-frontend/pages/dashboard.vue?macro=true";
import { default as discoverYtnFAmBIbzMeta } from "/builds/stavax/stavax-frontend/pages/discover.vue?macro=true";
import { default as indexwMR0wqFDvPMeta } from "/builds/stavax/stavax-frontend/pages/exchange/index.vue?macro=true";
import { default as _91slug_93aPozToI17PMeta } from "/builds/stavax/stavax-frontend/pages/game/[slug].vue?macro=true";
import { default as indexXi1yWQV5ovMeta } from "/builds/stavax/stavax-frontend/pages/index.vue?macro=true";
import { default as _91slug_9338sjukU5t6Meta } from "/builds/stavax/stavax-frontend/pages/my-nft/[slug].vue?macro=true";
import { default as indexTVkOQEqJ8NMeta } from "/builds/stavax/stavax-frontend/pages/my-nft/index.vue?macro=true";
import { default as indextRAByzuNkqMeta } from "/builds/stavax/stavax-frontend/pages/onboard/index.vue?macro=true";
import { default as passwordryuU7JB7pvMeta } from "/builds/stavax/stavax-frontend/pages/onboard/password.vue?macro=true";
import { default as restoreuX6ZOvOX2oMeta } from "/builds/stavax/stavax-frontend/pages/onboard/restore.vue?macro=true";
import { default as sign_45inYD6Q3VbCGbMeta } from "/builds/stavax/stavax-frontend/pages/onboard/sign-in.vue?macro=true";
import { default as change_45backupsfdSYD7aQPMeta } from "/builds/stavax/stavax-frontend/pages/password/change-backup.vue?macro=true";
import { default as change_45completionk8EW67oei0Meta } from "/builds/stavax/stavax-frontend/pages/password/change-completion.vue?macro=true";
import { default as changeWA9B88i7raMeta } from "/builds/stavax/stavax-frontend/pages/password/change.vue?macro=true";
import { default as revealhYac2l6T67Meta } from "/builds/stavax/stavax-frontend/pages/password/reveal.vue?macro=true";
import { default as indexXN9po5FUcfMeta } from "/builds/stavax/stavax-frontend/pages/session/[project_id]/index.vue?macro=true";
import { default as introducez6V4sbk29BMeta } from "/builds/stavax/stavax-frontend/pages/session/introduce.vue?macro=true";
import { default as listixBcK372rYMeta } from "/builds/stavax/stavax-frontend/pages/session/list.vue?macro=true";
import { default as settingQADVKOlHAeMeta } from "/builds/stavax/stavax-frontend/pages/setting.vue?macro=true";
import { default as telegrammvtCK6GUtiMeta } from "/builds/stavax/stavax-frontend/pages/telegram.vue?macro=true";
import { default as historyw5WU5xW1jRMeta } from "/builds/stavax/stavax-frontend/pages/transaction/history.vue?macro=true";
import { default as resultnfNHddp3nAMeta } from "/builds/stavax/stavax-frontend/pages/transaction/result.vue?macro=true";
import { default as index36R7swdOGMMeta } from "/builds/stavax/stavax-frontend/pages/withdraw/index.vue?macro=true";
export default [
  {
    name: backup_45introdvK6vJrECQMeta?.name ?? "account-backup-intro",
    path: backup_45introdvK6vJrECQMeta?.path ?? "/account/backup-intro",
    meta: backup_45introdvK6vJrECQMeta || {},
    alias: backup_45introdvK6vJrECQMeta?.alias || [],
    redirect: backup_45introdvK6vJrECQMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/account/backup-intro.vue").then(m => m.default || m)
  },
  {
    name: backuporHvffj8pMMeta?.name ?? "account-backup",
    path: backuporHvffj8pMMeta?.path ?? "/account/backup",
    meta: backuporHvffj8pMMeta || {},
    alias: backuporHvffj8pMMeta?.alias || [],
    redirect: backuporHvffj8pMMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/account/backup.vue").then(m => m.default || m)
  },
  {
    name: manage_45connectcpW5IDBRL9Meta?.name ?? "account-manage-connect",
    path: manage_45connectcpW5IDBRL9Meta?.path ?? "/account/manage-connect",
    meta: manage_45connectcpW5IDBRL9Meta || {},
    alias: manage_45connectcpW5IDBRL9Meta?.alias || [],
    redirect: manage_45connectcpW5IDBRL9Meta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/account/manage-connect.vue").then(m => m.default || m)
  },
  {
    name: upgrade_45walletNiJm0C3CReMeta?.name ?? "account-upgrade-wallet",
    path: upgrade_45walletNiJm0C3CReMeta?.path ?? "/account/upgrade-wallet",
    meta: upgrade_45walletNiJm0C3CReMeta || {},
    alias: upgrade_45walletNiJm0C3CReMeta?.alias || [],
    redirect: upgrade_45walletNiJm0C3CReMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/account/upgrade-wallet.vue").then(m => m.default || m)
  },
  {
    name: app_45securitypOiUpkSHx5Meta?.name ?? "app-security",
    path: app_45securitypOiUpkSHx5Meta?.path ?? "/app-security",
    meta: app_45securitypOiUpkSHx5Meta || {},
    alias: app_45securitypOiUpkSHx5Meta?.alias || [],
    redirect: app_45securitypOiUpkSHx5Meta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/app-security.vue").then(m => m.default || m)
  },
  {
    name: connection_45successzvR23ngDedMeta?.name ?? "connection-success",
    path: connection_45successzvR23ngDedMeta?.path ?? "/connection-success",
    meta: connection_45successzvR23ngDedMeta || {},
    alias: connection_45successzvR23ngDedMeta?.alias || [],
    redirect: connection_45successzvR23ngDedMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/connection-success.vue").then(m => m.default || m)
  },
  {
    name: depositvgkZrSh8wQMeta?.name ?? "currency-deposit",
    path: depositvgkZrSh8wQMeta?.path ?? "/currency/deposit",
    meta: depositvgkZrSh8wQMeta || {},
    alias: depositvgkZrSh8wQMeta?.alias || [],
    redirect: depositvgkZrSh8wQMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/currency/deposit.vue").then(m => m.default || m)
  },
  {
    name: indexAnYFKmfX4UMeta?.name ?? "currency",
    path: indexAnYFKmfX4UMeta?.path ?? "/currency",
    meta: indexAnYFKmfX4UMeta || {},
    alias: indexAnYFKmfX4UMeta?.alias || [],
    redirect: indexAnYFKmfX4UMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/currency/index.vue").then(m => m.default || m)
  },
  {
    name: qr_45codeKZbNicGwLdMeta?.name ?? "currency-qr-code",
    path: qr_45codeKZbNicGwLdMeta?.path ?? "/currency/qr-code",
    meta: qr_45codeKZbNicGwLdMeta || {},
    alias: qr_45codeKZbNicGwLdMeta?.alias || [],
    redirect: qr_45codeKZbNicGwLdMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/currency/qr-code.vue").then(m => m.default || m)
  },
  {
    name: dashboard0TcwC5SXfxMeta?.name ?? "dashboard",
    path: dashboard0TcwC5SXfxMeta?.path ?? "/dashboard",
    meta: dashboard0TcwC5SXfxMeta || {},
    alias: dashboard0TcwC5SXfxMeta?.alias || [],
    redirect: dashboard0TcwC5SXfxMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: discoverYtnFAmBIbzMeta?.name ?? "discover",
    path: discoverYtnFAmBIbzMeta?.path ?? "/discover",
    meta: discoverYtnFAmBIbzMeta || {},
    alias: discoverYtnFAmBIbzMeta?.alias || [],
    redirect: discoverYtnFAmBIbzMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/discover.vue").then(m => m.default || m)
  },
  {
    name: indexwMR0wqFDvPMeta?.name ?? "exchange",
    path: indexwMR0wqFDvPMeta?.path ?? "/exchange",
    meta: indexwMR0wqFDvPMeta || {},
    alias: indexwMR0wqFDvPMeta?.alias || [],
    redirect: indexwMR0wqFDvPMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/exchange/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_93aPozToI17PMeta?.name ?? "game-slug",
    path: _91slug_93aPozToI17PMeta?.path ?? "/game/:slug()",
    meta: _91slug_93aPozToI17PMeta || {},
    alias: _91slug_93aPozToI17PMeta?.alias || [],
    redirect: _91slug_93aPozToI17PMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/game/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexXi1yWQV5ovMeta?.name ?? "index",
    path: indexXi1yWQV5ovMeta?.path ?? "/",
    meta: indexXi1yWQV5ovMeta || {},
    alias: indexXi1yWQV5ovMeta?.alias || [],
    redirect: indexXi1yWQV5ovMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: _91slug_9338sjukU5t6Meta?.name ?? "my-nft-slug",
    path: _91slug_9338sjukU5t6Meta?.path ?? "/my-nft/:slug()",
    meta: _91slug_9338sjukU5t6Meta || {},
    alias: _91slug_9338sjukU5t6Meta?.alias || [],
    redirect: _91slug_9338sjukU5t6Meta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/my-nft/[slug].vue").then(m => m.default || m)
  },
  {
    name: indexTVkOQEqJ8NMeta?.name ?? "my-nft",
    path: indexTVkOQEqJ8NMeta?.path ?? "/my-nft",
    meta: indexTVkOQEqJ8NMeta || {},
    alias: indexTVkOQEqJ8NMeta?.alias || [],
    redirect: indexTVkOQEqJ8NMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/my-nft/index.vue").then(m => m.default || m)
  },
  {
    name: indextRAByzuNkqMeta?.name ?? "onboard",
    path: indextRAByzuNkqMeta?.path ?? "/onboard",
    meta: indextRAByzuNkqMeta || {},
    alias: indextRAByzuNkqMeta?.alias || [],
    redirect: indextRAByzuNkqMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/onboard/index.vue").then(m => m.default || m)
  },
  {
    name: passwordryuU7JB7pvMeta?.name ?? "onboard-password",
    path: passwordryuU7JB7pvMeta?.path ?? "/onboard/password",
    meta: passwordryuU7JB7pvMeta || {},
    alias: passwordryuU7JB7pvMeta?.alias || [],
    redirect: passwordryuU7JB7pvMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/onboard/password.vue").then(m => m.default || m)
  },
  {
    name: restoreuX6ZOvOX2oMeta?.name ?? "onboard-restore",
    path: restoreuX6ZOvOX2oMeta?.path ?? "/onboard/restore",
    meta: restoreuX6ZOvOX2oMeta || {},
    alias: restoreuX6ZOvOX2oMeta?.alias || [],
    redirect: restoreuX6ZOvOX2oMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/onboard/restore.vue").then(m => m.default || m)
  },
  {
    name: sign_45inYD6Q3VbCGbMeta?.name ?? "onboard-sign-in",
    path: sign_45inYD6Q3VbCGbMeta?.path ?? "/onboard/sign-in",
    meta: sign_45inYD6Q3VbCGbMeta || {},
    alias: sign_45inYD6Q3VbCGbMeta?.alias || [],
    redirect: sign_45inYD6Q3VbCGbMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/onboard/sign-in.vue").then(m => m.default || m)
  },
  {
    name: change_45backupsfdSYD7aQPMeta?.name ?? "password-change-backup",
    path: change_45backupsfdSYD7aQPMeta?.path ?? "/password/change-backup",
    meta: change_45backupsfdSYD7aQPMeta || {},
    alias: change_45backupsfdSYD7aQPMeta?.alias || [],
    redirect: change_45backupsfdSYD7aQPMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/password/change-backup.vue").then(m => m.default || m)
  },
  {
    name: change_45completionk8EW67oei0Meta?.name ?? "password-change-completion",
    path: change_45completionk8EW67oei0Meta?.path ?? "/password/change-completion",
    meta: change_45completionk8EW67oei0Meta || {},
    alias: change_45completionk8EW67oei0Meta?.alias || [],
    redirect: change_45completionk8EW67oei0Meta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/password/change-completion.vue").then(m => m.default || m)
  },
  {
    name: changeWA9B88i7raMeta?.name ?? "password-change",
    path: changeWA9B88i7raMeta?.path ?? "/password/change",
    meta: changeWA9B88i7raMeta || {},
    alias: changeWA9B88i7raMeta?.alias || [],
    redirect: changeWA9B88i7raMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/password/change.vue").then(m => m.default || m)
  },
  {
    name: revealhYac2l6T67Meta?.name ?? "password-reveal",
    path: revealhYac2l6T67Meta?.path ?? "/password/reveal",
    meta: revealhYac2l6T67Meta || {},
    alias: revealhYac2l6T67Meta?.alias || [],
    redirect: revealhYac2l6T67Meta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/password/reveal.vue").then(m => m.default || m)
  },
  {
    name: indexXN9po5FUcfMeta?.name ?? "session-project_id",
    path: indexXN9po5FUcfMeta?.path ?? "/session/:project_id()",
    meta: indexXN9po5FUcfMeta || {},
    alias: indexXN9po5FUcfMeta?.alias || [],
    redirect: indexXN9po5FUcfMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/session/[project_id]/index.vue").then(m => m.default || m)
  },
  {
    name: introducez6V4sbk29BMeta?.name ?? "session-introduce",
    path: introducez6V4sbk29BMeta?.path ?? "/session/introduce",
    meta: introducez6V4sbk29BMeta || {},
    alias: introducez6V4sbk29BMeta?.alias || [],
    redirect: introducez6V4sbk29BMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/session/introduce.vue").then(m => m.default || m)
  },
  {
    name: listixBcK372rYMeta?.name ?? "session-list",
    path: listixBcK372rYMeta?.path ?? "/session/list",
    meta: listixBcK372rYMeta || {},
    alias: listixBcK372rYMeta?.alias || [],
    redirect: listixBcK372rYMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/session/list.vue").then(m => m.default || m)
  },
  {
    name: settingQADVKOlHAeMeta?.name ?? "setting",
    path: settingQADVKOlHAeMeta?.path ?? "/setting",
    meta: settingQADVKOlHAeMeta || {},
    alias: settingQADVKOlHAeMeta?.alias || [],
    redirect: settingQADVKOlHAeMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/setting.vue").then(m => m.default || m)
  },
  {
    name: telegrammvtCK6GUtiMeta?.name ?? "telegram",
    path: telegrammvtCK6GUtiMeta?.path ?? "/telegram",
    meta: telegrammvtCK6GUtiMeta || {},
    alias: telegrammvtCK6GUtiMeta?.alias || [],
    redirect: telegrammvtCK6GUtiMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/telegram.vue").then(m => m.default || m)
  },
  {
    name: historyw5WU5xW1jRMeta?.name ?? "transaction-history",
    path: historyw5WU5xW1jRMeta?.path ?? "/transaction/history",
    meta: historyw5WU5xW1jRMeta || {},
    alias: historyw5WU5xW1jRMeta?.alias || [],
    redirect: historyw5WU5xW1jRMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/transaction/history.vue").then(m => m.default || m)
  },
  {
    name: resultnfNHddp3nAMeta?.name ?? "transaction-result",
    path: resultnfNHddp3nAMeta?.path ?? "/transaction/result",
    meta: resultnfNHddp3nAMeta || {},
    alias: resultnfNHddp3nAMeta?.alias || [],
    redirect: resultnfNHddp3nAMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/transaction/result.vue").then(m => m.default || m)
  },
  {
    name: index36R7swdOGMMeta?.name ?? "withdraw",
    path: index36R7swdOGMMeta?.path ?? "/withdraw",
    meta: index36R7swdOGMMeta || {},
    alias: index36R7swdOGMMeta?.alias || [],
    redirect: index36R7swdOGMMeta?.redirect,
    component: () => import("/builds/stavax/stavax-frontend/pages/withdraw/index.vue").then(m => m.default || m)
  }
]