import { defineStore } from "pinia";

export const useAppSetting = defineStore("appSetting", {
  state: () => ({
    showLoading: false,
    loadAppDone: false,
    load_biometric: false,
    sid: "",
    next_href: "",
    enable_biometric: useLocalStorage("enable_biometric", true),
    backUpIntro: useLocalStorage("view-backup-intro", false),
    currentWcWallet: useLocalStorage("current-wc-wallet", ""),
  }),
  actions: {
    changeNextHref(value: string) {
      this.next_href = value;
    },
    changeLoading(value: boolean) {
      this.showLoading = value;
    },
    saveLoadApp() {
      this.loadAppDone = true;
    },
    saveLoadBiometric() {
      this.load_biometric = true;
    },
    saveSid(newValue: string) {
      this.sid = newValue;
    },
    changeEnableBiometric(value: boolean) {
      this.enable_biometric = value;
    },
    saveBackupIntro(newValue: boolean) {
      this.backUpIntro = newValue;
    },
    saveCurrentWcWallet(newValue: string) {
      this.currentWcWallet = newValue;
    },
  },
});
