import axios from "axios";
import type { AxiosError, AxiosRequestConfig, AxiosResponse } from "axios";
import _, { isEmpty } from "lodash";
import { AppConfig } from "@/utils/AppConfig";
import { v4 as uuid } from "uuid";

export const FETCH_DOMAIN = {
  TOKEN_RATE: "@apiTokenRate",
  API: "@api",
};

class FetchInstance {
  getUrl = (url: string) => {
    return url.replace("@api", `${AppConfig.env.API_BASE_URL}`);
  };

  paramsSerializer = (params: any) => {
    const serializerParams = Object.entries(params)
      .filter(([, value]) => {
        return Array.isArray(value) ? !isEmpty(value) : !!value;
      })
      .map(([key, value]) => {
        if (Array.isArray(value)) {
          return value.map((v) => `${encodeURIComponent(key)}=${encodeURIComponent(v)}`).join("&");
        }
        return `${encodeURIComponent(key)}=${encodeURIComponent(value as string | number | boolean)}`;
      })
      .join("&");
    return serializerParams;
  };

  getConfigWithToken = async (config: AxiosRequestConfig = {}): Promise<AxiosRequestConfig> => {
    let headers = config.headers || {};
    const storeClientId = localStorage.getItem("client_key");
    const project_id = localStorage.getItem("project_id");
    const id = storeClientId || uuid();
    if (!storeClientId) {
      localStorage.setItem("client_key", id);
    }
    headers = {
      "X-Client-Key": id,
      "X-Project-Id": project_id || "",
      ...headers,
    };
    const accessToken = localStorage.getItem("access_token");
    if (accessToken) {
      headers = {
        Authorization: `Bearer ${accessToken}`,
        ...headers,
      };
    }
    return {
      ...config,
      headers,
      // paramsSerializer: this.paramsSerializer,
    };
  };

  handlerError = async ({ err, reject }: any) => {
    reject(err);
  };

  get = <ResponseType>(url: string, config?: AxiosRequestConfig) => {
    return new Promise(async (res: (value: AxiosResponse<ResponseType>) => void, reject: (axiosError: AxiosError) => void) => {
      axios
        .get<ResponseType>(this.getUrl(url), await this.getConfigWithToken(config))
        .then((v: any) => res(v))
        .catch(async (err) => {
          this.handlerError({
            err,
            res,
            reject,
            _data: { url, config },
            method: "get",
          });
        });
    });
  };

  delete = async <ResponseType>(url: string, config?: AxiosRequestConfig) => {
    return axios.delete<ResponseType>(this.getUrl(url), await this.getConfigWithToken(config));
  };

  post = async <ResponseType>(url: string, data: any, config?: AxiosRequestConfig) => {
    return new Promise(async (res: (value: AxiosResponse<ResponseType>) => void, reject: (axiosError: AxiosError) => void) => {
      axios
        .post<ResponseType>(this.getUrl(url), data, await this.getConfigWithToken(config))
        .then((v: any) => res(v))
        .catch(async (err) => {
          this.handlerError({
            err,
            res,
            reject,
            _data: { url, config, data },
            method: "post",
          });
        });
    });
  };

  patch = async <ResponseType>(url: string, data: Record<string, any> = {}, config?: AxiosRequestConfig) => {
    return axios.patch<ResponseType>(this.getUrl(url), data, await this.getConfigWithToken(config));
  };

  put = async <ResponseType>(url: string, data: Record<string, any> = {}, config?: AxiosRequestConfig) => {
    return axios.put<ResponseType>(this.getUrl(url), data, await this.getConfigWithToken(config));
  };

  getOtherUrl = <ResponseType>(url: string) => {
    return new Promise(async (res: (value: AxiosResponse<ResponseType>) => void, reject: (axiosError: AxiosError) => void) => {
      axios
        .get<ResponseType>(url, {
          headers: {
            "User-Agent":
              "Mozilla/5.0 (Macintosh; Intel Mac OS X 12_1_0) AppleWebKit/537.36 (KHTML, like Gecko) ReactNativeDebugger/0.12.1 Chrome/87.0.4280.141 Electron/11.4.6 Safari/537.36",
          },
        })
        .then((v: any) => res(v))
        .catch(async (err) => {
          this.handlerError({
            err,
            res,
            reject,
            _data: { url },
            method: "get",
          });
        });
    });
  };
}

// Singleton
const Fetch = new FetchInstance();

export default Fetch;
