import { privateKeyToAccount } from "viem/accounts";
import Crypto from "../encrypt/EncryptionService";
import { HmacSHA256 } from "crypto-js";

class ModulePassword {
  currentPwd = "";

  showCheckRemember() {
    const setting = useSettingStore();
    const duration = setting.getSetting().remoteConfig.check_pwd_duration;
    const time = this.getTimeSavePwd();
    const now = new Date().getTime();
    return now > time + (duration || 86400 * 14 * 1000);
  }

  getTimeSavePwd() {
    const timeSave = Number(localStorage.getItem("TIME_SAVE_PWD") || "0");
    return timeSave;
  }

  resetTimeSavePwd() {
    const timeSave = new Date().getTime();
    localStorage.setItem("TIME_SAVE_PWD", timeSave.toString());
    return timeSave;
  }

  savePasswordToStore(value: string) {
    const auth = useAuthStore();
    const user = auth.getUser();

    const clientKey = localStorage.getItem("client_key");

    const encPwd = Crypto.encrypt(value, clientKey || "");
    user.current_session = {
      data: {
        session_key: encPwd,
      },
    };
    auth.setUser(user);
    console.log("savePasswordToStore", value);
    this.currentPwd = value;
    return encPwd;
  }

  getPassword() {
    try {
      if (this.currentPwd) {
        return this.currentPwd;
      }
      const auth = useAuthStore();
      const user = auth.getUser();
      if (user.current_session?.data.session_key) {
        const clientKey = localStorage.getItem("client_key");
        const session_key = user.current_session.data.session_key;
        const storePwd = Crypto.decrypt(session_key, clientKey || "");
        this.currentPwd = storePwd;
        return storePwd;
      }
      return "";
    } catch (error) {
      return "";
    }
  }

  checkInputPassword(inputPwd: string) {
    const { getUser } = useAuthStore();

    const normalWallet = getUser().smart_wallets.find((w) => !w.blockchain);

    if (normalWallet) {
      try {
        const encPvk = normalWallet.encrypted_credential;
        const pvk = Crypto.decrypt(encPvk, inputPwd);
        privateKeyToAccount(pvk as any);
        const encPwd = this.savePasswordToStore(inputPwd);

        return encPwd;
      } catch (error) {
        throw new Error("Password is incorrect");
      }
    }
    const firstWallet = getUser().smart_wallets[0];
    if (!firstWallet) throw new Error("No wallet found");

    const encAddress = firstWallet.encrypted_credential;
    const eoa_address = firstWallet.eoa_address;
    const saveAddress = Crypto.decrypt(encAddress, inputPwd);
    console.log(saveAddress.toLocaleLowerCase(), "---", eoa_address.toLocaleLowerCase());

    if (saveAddress.toLocaleLowerCase() !== eoa_address.toLocaleLowerCase()) {
      throw new Error("Password is incorrect");
    }
    const encPwd = this.savePasswordToStore(inputPwd);
    return encPwd;
  }

  getHashedPwd() {
    return HmacSHA256(this.getPassword(), "stavax-account").toString();
  }

  comparePwdHash(serverHash: string) {
    if (!serverHash) return true;
    const hashed = HmacSHA256(this.getPassword(), "stavax-account").toString();
    return hashed === serverHash;
  }
}

const PasswordService = new ModulePassword();

export default PasswordService;
