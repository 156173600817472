import { toast } from "~/components/ui/toast";
import Fetch from "..";
import errorHandler from "../ErrorHandler";
import { IAuthConnectSession, IAuthLimit, IUserProfile, ISmartWallet, BLOCKCHAIN_TYPE } from "./type";

export async function authTelegram(initData: string): Promise<IAuthLimit> {
  try {
    const { data } = await Fetch.post<{ data: IAuthLimit }>(`@api/auth/telegram`, {
      init_data: initData,
    });
    return data.data;
  } catch (error) {
    console.error("authTelegram er", error);
    return { access_token: "", id: "" };
  }
}

export async function getUserInfo(): Promise<IUserProfile> {
  try {
    const { data } = await Fetch.get<{ data: IUserProfile }>(`@api/users/profile`);

    return data.data;
  } catch (error) {
    console.log("getUserInfo er", error);
    return { name: "" } as IUserProfile;
  }
}

export async function createSmartWallet(enc: string, address: string, hint: string, blockchain: BLOCKCHAIN_TYPE, key_id: string): Promise<any> {
  try {
    const { data } = await Fetch.post<{ data: IAuthLimit }>(`@api/smart-wallets/new`, {
      encrypted_credential: enc,
      eoa_address: address,
      blockchain,
      mpc_key_id: key_id,
      data: {
        password_hint: hint,
      },
    });
    console.log("createSmartWallet", data);
    return data.data;
  } catch (error) {
    console.log("createSmartWallet er", error);
    // @ts-ignore
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: msg,
      variant: "destructive",
      duration: 3000,
    });
    throw new Error("Create smart wallet error, please try again later!");
  }
}

export async function postAuthSession(pwdEnc: string) {
  try {
    const { data } = await Fetch.post<{ data: IAuthLimit }>(`@api/user-sessions/new`, {
      data: {
        session_key: pwdEnc,
      },
    });
    return data.data;
  } catch (error) {
    console.log("postAuthSession er", error);
    return { access_token: "" };
  }
}

export async function putUpdateCredential(params: { enc: string; address: string; hint?: string }) {
  try {
    const { data } = await Fetch.put<{ data: ISmartWallet }>(`@api/smart-wallets/${params.address}/credential`, {
      data: {
        password_hint: params.hint,
      },
      encrypted_credential: params.enc,
      eoaAddress: params.address,
    });
    return data.data;
  } catch (error) {
    console.log("putUpdateCredential er", error);
    toast({
      description: errorHandler(error).getAllMessagesString(),
      duration: 3000,
    });
    return null;
  }
}

export async function postAuthen(provider: "google" | "telegram", params: any) {
  try {
    const { data } = await Fetch.post<{ data: IAuthLimit }>(`@api/auth/login`, {
      provider,
      data: params,
    });
    if (!data.data)
      toast({
        description: "We can't recognize you. Please try again.",
        // duration: 3000,
      });
    return data.data;
  } catch (error) {
    console.log("postAuthen er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Error",
      description: msg,
      // duration: 3000,
    });
    return null;
  }
}

export async function postUpdateTimeLock(timeLock: number) {
  try {
    const { data } = await Fetch.put<{ data: IAuthLimit }>(`@api/users/settings`, {
      app_lock_seconds: timeLock,
    });
    return data.data;
  } catch (error) {
    console.log("postUpdateTimeLock er", error);
    return "";
  }
}
export async function createUserAuthen(provider: "google" | "telegram", params: any) {
  try {
    const { data } = await Fetch.post<{ data: IAuthLimit }>(`@api/auth/register`, {
      provider,
      data: params,
    });
    return data.data;
  } catch (error) {
    console.log("createUserAuthen er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Error",
      description: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function linkProviderAccount(provider: "google" | "telegram", params: any) {
  try {
    const { data } = await Fetch.post<{ data: IAuthLimit }>(`@api/auth/link-account`, {
      login_request: {
        provider,
        data: params,
      },
    });
    return data.data;
  } catch (error) {
    console.log("linkProviderAccount er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Error",
      description: msg,
      duration: 3000,
    });
  }
}

export async function fetchConnectSession(provider: "google" | "telegram", id?: string) {
  try {
    const { data } = await Fetch.get<{ data: IAuthConnectSession }>(`@api/auth/connect-sessions${id ? `/${id}` : ""}`, {
      params: {
        provider,
      },
    });
    return data.data;
  } catch (error) {
    console.log("fetchConnectSession er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Error",
      description: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function createConnectSession(provider: "google" | "telegram") {
  try {
    const { data } = await Fetch.post<{ data: IAuthConnectSession }>(`@api/auth/connect-sessions/new`, {
      provider,
      return_url: `${window.location.host.includes("localhost") ? "http://" : "https://"}${window.location.host}/connection-success`,
    });
    return data.data;
  } catch (error) {
    console.log("fetchConnectSession er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Error",
      description: msg,
      duration: 3000,
    });
    return null;
  }
}

export async function postResetWallet() {
  try {
    await Fetch.post(`@api/smart-wallets/reset-data`, {});
    return true;
  } catch (error) {
    console.log("postResetWallet er", error);
    const msg = errorHandler(error).getAllMessagesString();
    toast({
      title: "Error",
      description: msg,
      duration: 3000,
    });
    throw new Error(msg);
  }
}
